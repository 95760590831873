define("discourse/plugins/discourse-radiant-member/discourse/components/radiant-info", ["exports", "@ember/component", "discourse-common/utils/decorators"], function (_exports, _component, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function splitGroup(item) {
    const x = item.split(":");
    return {
      group: x[0],
      required: x[1]
    };
  }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.default)("siteSettings.radiant_group_values"), (_obj = {
    groupStatus(values) {
      let required = values.split("|").map(splitGroup);
      return required;
    }
  }, (_applyDecoratedDescriptor(_obj, "groupStatus", [_dec], Object.getOwnPropertyDescriptor(_obj, "groupStatus"), _obj)), _obj)));
});